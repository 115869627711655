import Vue from 'vue'
import ButtonPrimary from '~/components/elements/buttons/primary'
import ButtonSecondary from '~/components/elements/buttons/secondary'
import ButtonTertiary from '~/components/elements/buttons/tertiary'

import EmbedIframe from '~/components/elements/embeds/iframe'

import FormMain from '~/components/elements/forms/form'
import FormLabel from '~/components/elements/forms/label'
import FormCheckbox from '~/components/elements/forms/checkbox'
import FormInput from '~/components/elements/forms/input'
import FormRadio from '~/components/elements/forms/radio'
import FormSelect from '~/components/elements/forms/select'
import FormTextarea from '~/components/elements/forms/textarea'

import HeaderH1 from '~/components/elements/headers/h1'
import HeaderH2 from '~/components/elements/headers/h2'
import HeaderH3 from '~/components/elements/headers/h3'
import HeaderH4 from '~/components/elements/headers/h4'
import HeaderH5 from '~/components/elements/headers/h5'
import HeaderH6 from '~/components/elements/headers/h6'

import ImageMain from '~/components/elements/images/image'

import ListUl from '~/components/elements/lists/ul'
import ListOl from '~/components/elements/lists/ol'

import TableMain from '~/components/elements/tables/table'

Vue.component('ButtonPrimary', ButtonPrimary)
Vue.component('ButtonSecondary', ButtonSecondary)
Vue.component('ButtonTertiary', ButtonTertiary)

Vue.component('EmbedIframe', EmbedIframe)

Vue.component('FormMain', FormMain)
Vue.component('FormLabel', FormLabel)
Vue.component('FormCheckbox', FormCheckbox)
Vue.component('FormInput', FormInput)
Vue.component('FormRadio', FormRadio)
Vue.component('FormSelect', FormSelect)
Vue.component('FormTextarea', FormTextarea)

Vue.component('HeaderH1', HeaderH1)
Vue.component('HeaderH2', HeaderH2)
Vue.component('HeaderH3', HeaderH3)
Vue.component('HeaderH4', HeaderH4)
Vue.component('HeaderH5', HeaderH5)
Vue.component('HeaderH6', HeaderH6)

Vue.component('ImageMain', ImageMain)

Vue.component('ListUl', ListUl)
Vue.component('ListOl', ListOl)

Vue.component('TableMain', TableMain)
