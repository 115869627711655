import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone, faEnvelope, faSearch, faUser, faShoppingBasket, faHeart, faHome, faChevronLeft, faChevronRight, faSave, faPencilAlt, faArrowRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faTwitter, faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = true;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(faPhone, faEnvelope, faSearch, faUser, faShoppingBasket, faHeart, faHome, faChevronLeft, faChevronRight, faSave, faPencilAlt, faArrowRight, faCircleNotch, faWhatsapp, faTwitter, faFacebookF, faInstagram, faLinkedinIn, farHeart);

// Register the component globally
Vue.component('font-awesome-icon', FontAwesomeIcon);
